import PropTypes from "prop-types"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { MdExpandLess } from "react-icons/md"

import { Link, animateScroll as scroll } from "react-scroll"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityCompanyInfo(id: { eq: "374915a7-b0b9-5db3-86bb-41ad20130b6b" }) {
        logo {
          asset {
            fluid(maxWidth: 10) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  `)

  return (
    <header id="top">
      <AniLink cover bg="#69b56b" to="/" className="logo_wrapper">
        <Img
          fluid={data.sanityCompanyInfo.logo.asset.fluid}
          alt="Zignifikant logo"
        />
      </AniLink>
      <div
        role="button"
        className="burger"
        onClick={burgerClicked}
        onKeyDown={burgerClicked}
      >
        <div></div>
        <div></div>
      </div>
      <nav>
        <div className="link_wrapper">
          <AniLink cover bg="#69b56b" activeClassName="active" to="/om-os/">
            Om os
          </AniLink>
        </div>

        <div className="link_container">
          <div className="link_wrapper">
            <span>Aktiviteter</span>
            <MdExpandLess className="link_arrow" onClick={arrowClicked} />
          </div>
          <ul className="hidden_links">
            <li>
              <AniLink
                cover
                bg="#69b56b"
                activeClassName="active"
                to={`/aarsmoede/`}
              >
                Årsmøde
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                bg="#69b56b"
                activeClassName="active"
                to={`/kommende-aktiviteter/`}
              >
                Kommende aktiviteter
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                bg="#69b56b"
                activeClassName="active"
                to={`/tidligere-aktiviteter/`}
              >
                Tidligere aktiviteter
              </AniLink>
            </li>
          </ul>
        </div>
        <div className="link_wrapper">
          <AniLink
            cover
            bg="#69b56b"
            activeClassName="active"
            to="/medlemskab/"
          >
            Medlemskab
          </AniLink>
        </div>

        <div className="link_wrapper">
          <AniLink cover bg="#69b56b" activeClassName="active" to="/nyheder/">
            nyheder
          </AniLink>
        </div>

        <div className="link_wrapper">
          <Link
            className="headerContactLink"
            activeClass="active"
            to="kontakt"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMenuOnMobile}
          >
            Kontakt
          </Link>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

function burgerClicked() {
  document.querySelector("nav").classList.toggle("open_menu")
  document.querySelector(".burger").classList.toggle("open_burger")
}

export default Header

function arrowClicked(event) {
  let target = event.target
  let parent = target.parentElement
  let hiddenLinks = parent.nextSibling
  let height = hiddenLinks.scrollHeight
  const allHiddenLinks = document.querySelectorAll(".hidden_links")
  const allArrows = document.querySelectorAll(".link_arrow")

  allHiddenLinks.forEach(links => {
    links.style.maxHeight = "0px"
    links.classList.remove("active")
  })
  allArrows.forEach(arrow => {
    arrow.classList.remove("link_arrow_up")
  })

  if (hiddenLinks.classList.contains("active") === true) {
    hiddenLinks.style.maxHeight = "0px"
    hiddenLinks.classList.remove("active")

    target.classList.remove("link_arrow_up")

    // allHiddenLinks.forEach(links => {
    //   links.classList.remove("active")

    //   target.classList.remove("link_arrow_up")
    // })
  } else {
    hiddenLinks.style.maxHeight = height + "px"
    target.classList.add("link_arrow_up")

    hiddenLinks.classList.add("active")
  }
}

function closeMenuOnMobile() {
  document.querySelector("nav").classList.remove("open_menu")
  document.querySelector(".burger").classList.remove("open_burger")
}

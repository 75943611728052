import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MdLocationOn, MdArrowDropUp } from "react-icons/md"
import { Link, animateScroll as scroll } from "react-scroll"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityFooter {
        title
        zipCode
        email
        description
        city
        address
        image {
          hotspot {
            x
            y
          }
          asset {
            url
            fluid {
              src
            }
          }
        }
      }
      sanityCompanyInfo(id: { eq: "374915a7-b0b9-5db3-86bb-41ad20130b6b" }) {
        name
      }
    }
  `)

  const positionStyles = {
    backgroundImage: `url(${data.sanityFooter.image.asset.fluid.src})`,
    backgroundPositionX: `${data.sanityFooter.image.hotspot.x * 100}%`,
    backgroundPositionY: `${data.sanityFooter.image.hotspot.y * 100}%`,
    backgroundSize: "100%",
  }

  return (
    <footer id="kontakt">
      <div className="footerWrapper">
        <div alt={data.sanityFooter.title} style={positionStyles}></div>

        <div className="footerText">
          <h2>{data.sanityFooter.title}</h2>
          <a href={`mailto:${data.sanityFooter.email}`}>
            {" "}
            {data.sanityFooter.email}{" "}
          </a>
          <p>{data.sanityFooter.description}</p>

          <div className="addressWrapper">
            <MdLocationOn />
            <p>{data.sanityFooter.address}</p>
            <div className="cityZip">
              <p> &nbsp; | &nbsp; </p>
              <p>{data.sanityFooter.zipCode}, </p>
              <p> &nbsp; {data.sanityFooter.city}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        {data.sanityCompanyInfo.name} © {new Date().getFullYear()}
        <Link
          className="headerContactLink"
          activeClass="active"
          to="top"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          style={{ cursor: "pointer" }}
        >
          <MdArrowDropUp />
        </Link>
      </div>
      <div className="copyright">
        <a href="www.kodexcph.com">
          <p>Build by kodexcph.com</p>
        </a>
      </div>
    </footer>
  )
}

export default Footer
